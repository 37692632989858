import React from "react";
import Layout from "./Layout";
import FlankCore from "../assets/projects/flank_add_resource.png";
import FlankRecruiter from "../assets/projects/flank_recruiter.png";
import HackAISite from "../assets/projects/hackai_site.png";

interface Project {
  id: string;
  title: string;
  description: string;
  technologies: string[];
  challenges: string;
  solutions: string;
  outcome: string;
  links: {
    demo?: string;
    repository?: string;
  };
  image: string;
}

const projects: Project[] = [
  {
    id: "1",
    title: "Flank Core App",
    description:
      "Developed new features for the core Flank app, a tool designed to speed up the development of internal tools. The platform looks at a company's backend services like APIs, serverless functions, SQL stored procs, and more, then it automatically generates a UI for their teammates to start using right away.",
    technologies: [
      "VueJS",
      "TailwindCSS",
      "TypeScript",
      "Python",
      "FastAPI",
      "SQLAlchemy",
      "PostgreSQL",
    ],
    challenges:
      "Integrating multiple technologies and ensuring seamless user experience across different features.",
    solutions:
      "Implemented modular architecture and used TypeScript for type safety across the stack.",
    outcome:
      "Successfully launched multiple features that improved user engagement and streamlined recruitment processes.",
    links: {
      demo: "https://www.flank.cloud/", // replace with actual demo link when available
      //   repository: "https://github.com/flank/core-app", // replace with actual repo link when available
    },
    image: FlankCore, // replace with actual image path
  },
  {
    id: "2",
    title: "Flank Recruitment Tools",
    description:
      "Automated LeadGen processes for recruiters using advanced AI and web scraping techniques.",
    technologies: ["Python", "Playwright", "Langchain", "OpenAI", "AWS Lambda"],
    challenges:
      "Optimizing runtime for large-scale data processing and integrating AI for advanced filtering.",
    solutions:
      "Leveraged AWS Lambda's concurrency and implemented efficient AI algorithms.",
    outcome: "Acquired over 40 clients in 4 months, generating $1000+ MRR.",
    links: {
      //   repository: "https://github.com/flank/recruitment-tools", // replace with actual repo link when available
    },
    image: FlankRecruiter, // replace with actual image path
  },
  {
    id: "3",
    title: "HackAI Website",
    description:
      "Built a ReactJS website to support a community of 150+ AI enthusiasts.",
    technologies: ["ReactJS", "TailwindCSS", "TypeScript"],
    challenges:
      "Creating an engaging platform that caters to various skill levels in AI.",
    solutions:
      "Implemented interactive tutorials and resource sharing features.",
    outcome:
      "Successful monthly meetups and growing community engagement in AI technologies.",
    links: {
      demo: "https://hackai.live",
      //   repository: "https://github.com/hackai/website", // replace with actual repo link when available
    },
    image: HackAISite,
  },
];

const Projects: React.FC = () => {
  return (
    <Layout>
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-heading mb-8 text-gold">My Projects</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project) => (
            <div
              key={project.id}
              className="bg-beige bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg overflow-hidden shadow-lg"
            >
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h2 className="text-2xl font-heading mb-2 text-gold">
                  {project.title}
                </h2>
                <p className="mb-4">{project.description}</p>
                <h3 className="text-xl font-heading mb-2 text-gold">
                  Technologies
                </h3>
                <div className="flex flex-wrap gap-2 mb-4">
                  {project.technologies.map((tech, index) => (
                    <span
                      key={index}
                      className="bg-olive px-2 py-1 rounded-full text-sm"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
                <h3 className="text-xl font-heading mb-2 text-gold">
                  Challenges & Solutions
                </h3>
                <p className="mb-2">
                  <strong>Challenge:</strong> {project.challenges}
                </p>
                <p className="mb-4">
                  <strong>Solution:</strong> {project.solutions}
                </p>
                <h3 className="text-xl font-heading mb-2 text-gold">Outcome</h3>
                <p className="mb-4">{project.outcome}</p>
                <div className="flex gap-4">
                  {project.links.demo && (
                    <a
                      href={project.links.demo}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-gold text-darkGreen px-4 py-2 rounded-lg hover:bg-rust hover:text-beige transition-colors"
                    >
                      View Project
                    </a>
                  )}
                  {project.links.repository && (
                    <a
                      href={project.links.repository}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-olive text-beige px-4 py-2 rounded-lg hover:bg-rust hover:text-beige transition-colors"
                    >
                      View Code
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Projects;
