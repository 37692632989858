import React, { useState } from "react";
import Layout from "./Layout";
import { FaLinkedin, FaGithub, FaTwitter } from "react-icons/fa";

const Contact: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Here you would typically handle the form submission,
    // such as sending the data to a server or API
    console.log("Form submitted:", { name, email, message });
    // Reset form fields
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <Layout>
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-heading mb-8 text-gold">Get in Touch</h1>

        <p className="mb-8 text-lg">
          I'm always excited to connect with fellow developers, potential
          collaborators, or anyone interested in technology and innovation.
          Whether you have a question, a project idea, or just want to say
          hello, feel free to reach out!
        </p>

        <div className="grid md:grid-cols-2 gap-12">
          <div>
            <h2 className="text-2xl font-heading mb-4 text-gold">
              Contact Information
            </h2>
            <p className="mb-4">
              Email:{" "}
              <span className="font-mono">19bmiles at gmail dot com</span>
            </p>
            <p className="mb-4">Location: Austin, TX</p>
            <div className="flex space-x-4 mb-8">
              <a
                href="https://www.linkedin.com/in/bryce-miles/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-beige hover:text-gold"
              >
                <FaLinkedin size={24} />
              </a>
              <a
                href="https://github.com/19bmiles"
                target="_blank"
                rel="noopener noreferrer"
                className="text-beige hover:text-gold"
              >
                <FaGithub size={24} />
              </a>
              <a
                href="https://x.com/brycemiles0"
                target="_blank"
                rel="noopener noreferrer"
                className="text-beige hover:text-gold"
              >
                <FaTwitter size={24} />
              </a>
            </div>
          </div>

          <div>
            <h2 className="text-2xl font-heading mb-4 text-gold">
              Send a Message
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block mb-1">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="w-full px-3 py-2 bg-beige bg-opacity-10 rounded-md focus:outline-none focus:ring-2 focus:ring-gold"
                />
              </div>
              <div>
                <label htmlFor="email" className="block mb-1">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full px-3 py-2 bg-beige bg-opacity-10 rounded-md focus:outline-none focus:ring-2 focus:ring-gold"
                />
              </div>
              <div>
                <label htmlFor="message" className="block mb-1">
                  Message
                </label>
                <textarea
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  rows={4}
                  className="w-full px-3 py-2 bg-beige bg-opacity-10 rounded-md focus:outline-none focus:ring-2 focus:ring-gold"
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-gold text-darkGreen px-6 py-2 rounded-lg hover:bg-rust hover:text-beige transition-colors"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
