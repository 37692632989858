import React from "react";
import { useParams, Link } from "react-router-dom";
import Layout from "./Layout";

interface BlogPostData {
  id: string;
  title: string;
  excerpt: string;
  date: string;
  author: string;
  category: string;
  tags: string[];
  content: string;
}

const sampleBlogPosts: BlogPostData[] = [
  {
    id: "1",
    title: "The Future of AI in Software Development",
    excerpt:
      "Exploring how artificial intelligence is reshaping the landscape of software engineering.",
    date: "2024-09-15",
    author: "Bryce Miles",
    category: "Tech",
    tags: ["AI", "Software Development", "Future Tech"],
    content: "Full blog post content here...",
  },
  {
    id: "2",
    title: "My Journey Through Austin's Music Scene",
    excerpt:
      "A personal story about discovering hidden gems in Austin's vibrant music community.",
    date: "2024-09-10",
    author: "Bryce Miles",
    category: "Personal",
    tags: ["Music", "Austin", "Personal Story"],
    content: "Full blog post content here...",
  },
  {
    id: "3",
    title: "Building a RESTful API with FastAPI: A Step-by-Step Guide",
    excerpt:
      "Learn how to create a robust API using Python's FastAPI framework in this comprehensive tutorial.",
    date: "2024-09-05",
    author: "Bryce Miles",
    category: "Tutorial",
    tags: ["Python", "FastAPI", "Web Development"],
    content: "Full blog post content here...",
  },
];

const BlogPost: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const post = sampleBlogPosts.find((post) => post.id === id);

  if (!post) {
    return (
      <Layout>
        <div className="container mx-auto px-4 py-12">
          <h1 className="text-4xl font-heading mb-8 text-gold">
            Post not found
          </h1>
          <Link
            to="/blog"
            className="bg-gold text-darkGreen px-4 py-2 rounded-lg hover:bg-rust hover:text-beige transition-colors"
          >
            Back to Blog
          </Link>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-heading mb-4 text-gold">{post.title}</h1>
        <p className="text-sm mb-4">
          {post.date} | {post.author}
        </p>
        <div className="flex flex-wrap gap-2 mb-8">
          <span className="bg-olive px-2 py-1 rounded-full text-sm">
            {post.category}
          </span>
          {post.tags.map((tag) => (
            <span
              key={tag}
              className="bg-rust bg-opacity-50 px-2 py-1 rounded-full text-sm"
            >
              {tag}
            </span>
          ))}
        </div>
        <div className="prose prose-invert max-w-none mb-8">{post.content}</div>
        <Link
          to="/blog"
          className="bg-gold text-darkGreen px-4 py-2 rounded-lg hover:bg-rust hover:text-beige transition-colors"
        >
          Back to Blog
        </Link>
      </div>
    </Layout>
  );
};

export default BlogPost;
