import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navigation: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <nav className="bg-darkGreen bg-opacity-90 backdrop-filter backdrop-blur-lg fixed w-full z-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="text-gold text-xl font-heading">
              Bryce Miles
            </Link>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <Link
                to="/"
                className="text-beige hover:text-gold px-3 py-2 rounded-md text-sm font-medium"
              >
                Home
              </Link>
              {/* <Link
                to="/about"
                className="text-beige hover:text-gold px-3 py-2 rounded-md text-sm font-medium"
              >
                About
              </Link> */}
              <Link
                to="/projects"
                className="text-beige hover:text-gold px-3 py-2 rounded-md text-sm font-medium"
              >
                Projects
              </Link>
              {/* <Link
                to="/blog"
                className="text-beige hover:text-gold px-3 py-2 rounded-md text-sm font-medium"
              >
                Blog
              </Link> */}
              <Link
                to="/contact"
                className="text-beige hover:text-gold px-3 py-2 rounded-md text-sm font-medium"
              >
                Contact
              </Link>
            </div>
          </div>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-beige hover:text-gold">
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={
                    isMenuOpen
                      ? "M6 18L18 6M6 6l12 12"
                      : "M4 6h16M4 12h16M4 18h16"
                  }
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link
              to="/"
              className="text-beige hover:text-gold block px-3 py-2 rounded-md text-base font-medium"
            >
              Home
            </Link>
            {/* <Link
              to="/about"
              className="text-beige hover:text-gold block px-3 py-2 rounded-md text-base font-medium"
            >
              About
            </Link> */}
            <Link
              to="/projects"
              className="text-beige hover:text-gold block px-3 py-2 rounded-md text-base font-medium"
            >
              Projects
            </Link>
            {/* <Link
              to="/blog"
              className="text-beige hover:text-gold block px-3 py-2 rounded-md text-base font-medium"
            >
              Blog
            </Link> */}
            <Link
              to="/contact"
              className="text-beige hover:text-gold block px-3 py-2 rounded-md text-base font-medium"
            >
              Contact
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;
