import React from "react";
import Navigation from "./Navigation";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-darkGreen to-olive text-beige">
      <Navigation />
      <main className="pt-16">{children}</main>
      <footer className="bg-darkGreen text-beige p-4 mt-8">
        <div className="container mx-auto text-center">
          © {new Date().getFullYear()} Bryce Miles
        </div>
      </footer>
    </div>
  );
};

export default Layout;
