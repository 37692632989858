import React, { useState } from "react";
import { motion } from "framer-motion";
import Layout from "./Layout";

interface TimelineItem {
  id: string;
  date: string;
  title: string;
  description: string;
  category: string;
}

const timelineData: TimelineItem[] = [
  {
    id: "1",
    date: "Jan 2021 - Jun 2024",
    title: "Founding Engineer at Flank",
    description:
      "Developed new features using VueJS, TailwindCSS, and Typescript. Implemented CI/CD pipeline using GitHub actions and AWS.",
    category: "professional",
  },
  {
    id: "2",
    date: "Sep 2023 - Jun 2024",
    title: "Founder - Flank Recruitment Tools",
    description:
      "Developed automation tools for recruiters using Python, Playwright, and Langchain + OpenAI.",
    category: "tech",
  },
  {
    id: "3",
    date: "Aug 2019 - Jan 2021",
    title: "Software Engineer at Lockheed Martin",
    description:
      "Improved TypeScript practices, upgraded from AngularJS to Angular 9, and implemented serverless architecture using AWS.",
    category: "professional",
  },
  {
    id: "4",
    date: "Jul 2024 - Present",
    title: "Founder of HackAI",
    description:
      "Organized a group with 178 members, built a ReactJS website, and developed AI-related tutorials.",
    category: "tech",
  },
  {
    id: "5",
    date: "Jan 2020 - Jun 2023",
    title: "Head of Technology for Recruitment at Alpha Kappa Psi",
    description:
      "Built a ReactJS website for recruitment functions and developed a system using Google App and Python scripts.",
    category: "tech",
  },
  {
    id: "6",
    date: "Aug 2019 - Present",
    title: "Member of Technology Committee at HackPSU",
    description:
      "Developed front-end for club websites using Angular and provided technical support during hackathons.",
    category: "tech",
  },
  {
    id: "7",
    date: "Ongoing",
    title: "Music Enthusiast",
    description:
      "Passionate about New Music Friday and discovering new artists.",
    category: "music",
  },
  {
    id: "8",
    date: "Ongoing",
    title: "TV Show Fan",
    description:
      'Big fan of "How I Met Your Mother" and always looking for new series to binge.',
    category: "media",
  },
  {
    id: "9",
    date: "Ongoing",
    title: "Exploring Austin",
    description:
      "Enjoy discovering new spots in Austin and meeting new people.",
    category: "fun event",
  },
];

const categories = [
  "all",
  "professional",
  "tech",
  "music",
  "media",
  "fun event",
];

const About: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");

  const filteredTimeline =
    selectedCategory === "all"
      ? timelineData
      : timelineData.filter((item) => item.category === selectedCategory);

  return (
    <Layout>
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-heading mb-8 text-gold">About Me</h1>

        <div className="mb-8">
          <p className="text-lg mb-4">
            Hi, I'm Bryce Miles, a software engineer based in Austin, TX. I'm
            passionate about building innovative solutions and exploring new
            technologies. When I'm not coding, you can find me enjoying music,
            binging TV shows, or exploring the vibrant city of Austin.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-heading mb-4 text-gold">My Journey</h2>
          <div className="flex flex-wrap gap-2 mb-4">
            {categories.map((category) => (
              <button
                key={category}
                className={`px-4 py-2 rounded-full ${
                  selectedCategory === category
                    ? "bg-gold text-darkGreen"
                    : "bg-beige bg-opacity-20 text-beige"
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
          </div>
          <div className="space-y-8">
            {filteredTimeline.map((item, index) => (
              <motion.div
                key={item.id}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-beige bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg p-6"
              >
                <h3 className="text-xl font-heading mb-2 text-gold">
                  {item.title}
                </h3>
                <p className="text-sm mb-2">{item.date}</p>
                <p>{item.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
