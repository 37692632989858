import React from "react";
import { Link } from "react-router-dom";
import Layout from "./Layout";
import headshot from "../assets/headshot-cropped.jpg";

const Home: React.FC = () => {
  return (
    <Layout>
      <div className="container mx-auto px-4 py-12">
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
          <div className="md:w-1/2">
            <h1 className="text-5xl font-heading mb-6 text-gold">
              Hi, I'm Bryce Miles
            </h1>
            <h2 className="text-3xl font-heading mb-6 text-beige">
              Building Innovative Software Solutions in Austin, TX
            </h2>
            <p className="font-body mb-8 text-lg">
              I'm a software engineer passionate about creating all types of
              applications. I like learning about new technologies and building
              things. Welcome to my corner of the web!
            </p>
            <div className="flex gap-4">
              <Link
                to="/projects"
                className="px-6 py-3 bg-gold text-darkGreen font-bold rounded-lg transition-all hover:bg-rust hover:text-beige"
              >
                View My Projects
              </Link>
              {/* <Link
                to="/blog"
                className="px-6 py-3 border-2 border-beige text-beige font-bold rounded-lg transition-all hover:bg-beige hover:text-darkGreen"
              >
                Read My Blog
              </Link> */}
              <Link
                to="/contact"
                className="px-6 py-3 border-2 border-beige text-beige font-bold rounded-lg transition-all hover:bg-beige hover:text-darkGreen"
              >
                Contact Me!
              </Link>
            </div>
          </div>
          <div className="md:w-1/2 lg:p-20 p-10">
            <div className="bg-beige bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg p-20 md:p-12 shadow-lg">
              <img
                src={headshot}
                alt="Bryce Miles"
                className="w-full h-auto rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
