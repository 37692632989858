import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "./Layout";

interface BlogPostData {
  id: string;
  title: string;
  excerpt: string;
  date: string;
  author: string;
  category: string;
  tags: string[];
  content: string;
}

const sampleBlogPosts: BlogPostData[] = [
  {
    id: "1",
    title: "The Future of AI in Software Development",
    excerpt:
      "Exploring how artificial intelligence is reshaping the landscape of software engineering.",
    date: "2024-09-15",
    author: "Bryce Miles",
    category: "Tech",
    tags: ["AI", "Software Development", "Future Tech"],
    content: "Full blog post content here...",
  },
  {
    id: "2",
    title: "My Journey Through Austin's Music Scene",
    excerpt:
      "A personal story about discovering hidden gems in Austin's vibrant music community.",
    date: "2024-09-10",
    author: "Bryce Miles",
    category: "Personal",
    tags: ["Music", "Austin", "Personal Story"],
    content: "Full blog post content here...",
  },
  {
    id: "3",
    title: "Building a RESTful API with FastAPI: A Step-by-Step Guide",
    excerpt:
      "Learn how to create a robust API using Python's FastAPI framework in this comprehensive tutorial.",
    date: "2024-09-05",
    author: "Bryce Miles",
    category: "Tutorial",
    tags: ["Python", "FastAPI", "Web Development"],
    content: "Full blog post content here...",
  },
];

const allCategories = [
  "All",
  ...Array.from(new Set(sampleBlogPosts.map((post) => post.category))),
];
const allTags = [
  "All",
  ...Array.from(new Set(sampleBlogPosts.flatMap((post) => post.tags))),
];

const Blog: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedTag, setSelectedTag] = useState("All");

  const filteredPosts = sampleBlogPosts.filter(
    (post) =>
      (selectedCategory === "All" || post.category === selectedCategory) &&
      (selectedTag === "All" || post.tags.includes(selectedTag))
  );

  return (
    <Layout>
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-heading mb-8 text-gold">Blog</h1>

        <div className="mb-8">
          <h2 className="text-2xl font-heading mb-4 text-gold">Categories</h2>
          <div className="flex flex-wrap gap-2">
            {allCategories.map((category) => (
              <button
                key={category}
                className={`px-4 py-2 rounded-full ${
                  selectedCategory === category
                    ? "bg-gold text-darkGreen"
                    : "bg-beige bg-opacity-20 text-beige"
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-heading mb-4 text-gold">Tags</h2>
          <div className="flex flex-wrap gap-2">
            {allTags.map((tag) => (
              <button
                key={tag}
                className={`px-4 py-2 rounded-full ${
                  selectedTag === tag
                    ? "bg-gold text-darkGreen"
                    : "bg-beige bg-opacity-20 text-beige"
                }`}
                onClick={() => setSelectedTag(tag)}
              >
                {tag}
              </button>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredPosts.map((post) => (
            <div
              key={post.id}
              className="bg-beige bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg overflow-hidden shadow-lg"
            >
              <div className="p-6">
                <h2 className="text-2xl font-heading mb-2 text-gold">
                  {post.title}
                </h2>
                <p className="text-sm mb-2">
                  {post.date} | {post.author}
                </p>
                <p className="mb-4">{post.excerpt}</p>
                <div className="flex flex-wrap gap-2 mb-4">
                  <span className="bg-olive px-2 py-1 rounded-full text-sm">
                    {post.category}
                  </span>
                  {post.tags.map((tag) => (
                    <span
                      key={tag}
                      className="bg-rust bg-opacity-50 px-2 py-1 rounded-full text-sm"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
                <Link
                  to={`/blog/${post.id}`}
                  className="bg-gold text-darkGreen px-4 py-2 rounded-lg hover:bg-rust hover:text-beige transition-colors"
                >
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
